.App {
  text-align: center;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
  }
}

.App-header {
	background-color: #2E2E2E;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 1vmin);
	color: #A6A6A6;
	font-family: Consolas, "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", Monaco, "Courier New", "monospace";
}

.App-link {
  color: #61dafb;
}

h1 {
	font-size: calc(23px + 2vmin);
	letter-spacing: 6px;
	font-weight: bold;
	text-decoration: underline;
}

a {
	color:cadetblue;
	font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
